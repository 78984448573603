export const outgoingTables = [{
  patientName: "JoehWlek123",
  mrn: "1234567",
  gender: "male",
  dob: "2021-02-16",
  age: "18",
  caseNumber: "23412312",
  timeReceived: "2022-02-16",
  specimen: "Lung",
  status: "to do",
  sender: "Mike Kim"
},{
  patientName: "JoehWlek",
  mrn: "1234567",
  gender: "male",
  dob: "2021-02-16",
  age: "18",
  caseNumber: "23412312",
  timeReceived: "2022-02-16",
  specimen: "Lung",
  status: "to do",
  sender: "Mike Kim"
},{
  patientName: "JoehWlek",
  mrn: "1234567",
  gender: "male",
  dob: "2021-02-16",
  age: "18",
  caseNumber: "23412312",
  timeReceived: "2022-02-16",
  specimen: "Lung",
  status: "to do",
  sender: "Mike Kim"
},{
  patientName: "JoehWlek",
  mrn: "1234567",
  gender: "male",
  dob: "2021-02-16",
  age: "18",
  caseNumber: "23412312",
  timeReceived: "2022-02-16",
  specimen: "Lung",
  status: "to do",
  sender: "Mike Kim"
},{
  patientName: "JoehWlek",
  mrn: "1234567",
  gender: "male",
  dob: "2021-02-16",
  age: "18",
  caseNumber: "23412312",
  timeReceived: "2022-02-16",
  specimen: "Lung",
  status: "to do",
  sender: "Mike Kim"
},{
  patientName: "JoehWlek",
  mrn: "1234567",
  gender: "male",
  dob: "2021-02-16",
  age: "18",
  caseNumber: "23412312",
  timeReceived: "2022-02-16",
  specimen: "Lung",
  status: "to do",
  sender: "Mike Kim"
},{
  patientName: "JoehWlek",
  mrn: "1234567",
  gender: "male",
  dob: "2021-02-16",
  age: "18",
  caseNumber: "23412312",
  timeReceived: "2022-02-16",
  specimen: "Lung",
  status: "to do",
  sender: "Mike Kim"
},{
  patientName: "JoehWlek",
  mrn: "1234567",
  gender: "male",
  dob: "2021-02-16",
  age: "18",
  caseNumber: "23412312",
  timeReceived: "2022-02-16",
  specimen: "Lung",
  status: "to do",
  sender: "Mike Kim"
},{
  patientName: "JoehWlek",
  mrn: "1234567",
  gender: "male",
  dob: "2021-02-16",
  age: "18",
  caseNumber: "23412312",
  timeReceived: "2022-02-16",
  specimen: "Lung",
  status: "to do",
  sender: "Mike Kim"
},{
  patientName: "JoehWlek",
  mrn: "1234567",
  gender: "male",
  dob: "2021-02-16",
  age: "18",
  caseNumber: "23412312",
  timeReceived: "2022-02-16",
  specimen: "Lung",
  status: "to do",
  sender: "Mike Kim"
},{
  patientName: "JoehWlek",
  mrn: "1234567",
  gender: "male",
  dob: "2021-02-16",
  age: "18",
  caseNumber: "23412312",
  timeReceived: "2022-02-16",
  specimen: "Lung",
  status: "to do",
  sender: "Mike Kim"
},{
  patientName: "JoehWlek",
  mrn: "1234567",
  gender: "male",
  dob: "2021-02-16",
  age: "18",
  caseNumber: "23412312",
  timeReceived: "2022-02-16",
  specimen: "Lung",
  status: "to do",
  sender: "Mike Kim"
}];