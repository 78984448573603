import { useEffect, useState } from 'react'
import Item from './Item'
import Sub from './Sub'
import { SidebarContext } from "./context"
import { useHistory } from 'react-router-dom'


function Sidebar({ list, collapsed }) {
  // list: [{path, name, icon, components, openSub}]
  // collapsed: false 展开，true 收缩 外部点击事件
  // 展示下级菜单的列表
  const [showSubs, setShowSubs] = useState([])

  // 保证刷新页面的时候二级菜单依旧是展开状态
  let showSubArr = []
  const history = useHistory()

  useEffect(() => {
    showSubArr.push(showSubsInit(list, history.location.pathname))
    setShowSubs([...showSubArr])
  }, [])

  const showSubsInit = (list, historyPathName) => {
    let showSubPath = ''
    list.map(item => {
      const { childrens, path } = item
      if (childrens) {
        if (historyPathName.indexOf(path) > -1) {
          showSubPath = path
          if (showSubsInit(childrens, historyPathName)) showSubArr.push(showSubsInit(childrens, historyPathName))
        }
      }
    })
    return showSubPath
  }


  const [open, setOpen] = useState(true) // 鼠标划入展开/收缩
  const handleMouseEnter = () => { // 鼠标划入 展开
    if (!collapsed) return
    setOpen(true)
  }
  const handleMouseLeave = () => { // 鼠标划出 关闭
    if (!collapsed) return
    setOpen(false)
  }
  useEffect(() => {
    setOpen(!collapsed)
  }, [collapsed])

  return (
    <SidebarContext.Provider value={{ open, showSubs, setShowSubs }}>
      <div className={`bg-gray-900 opacity-50 fixed inset-0 z-10 lg:hidden ${collapsed ? 'hidden' : ''}`}></div>
      <aside
        className={`fixed z-20 h-full top-0 left-0 transition-all duration-75 
          lg:relative lg:flex lg:flex-shrink-0 flex-col
          ${open ? 'w-70' : 'w-16'}
          overflow-hidden
          ${collapsed ? 'hidden' : 'flex'}
        `}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="relative flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white pt-16 lg:pt-0">
          <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto scrollbar overflow-x-hidden">
            <div className="flex-1 px-3 bg-white divide-y space-y-1">
              <ul className="space-y-2 pb-2">
                <Children list={list}></Children>
              </ul>
            </div>
          </div>
        </div>
      </aside>
    </SidebarContext.Provider>
  )
}
export default Sidebar

function Children({ list }) {
  return (<>
    {
      list.map((router, index) => {
        const { name, childrens } = router
        return (
          <li key={index}>
            {
              !childrens && <Item router={router} />
            }
            {
              childrens && <Sub name={name} router={router}>
                {childrens ? <Children list={childrens} /> : null}
              </Sub>
            }
          </li>
        )
      })
    }
  </>
  )
}

