
import { useSelector, useDispatch } from "react-redux";
import Home from "../qg-components/src/aws-pages/Home";
import Dropdown from '../qg-components/src/libs/Dropdown/index';
import Avatar from '../qg-components/src/libs/Avatar/index';
import { NavLink } from 'react-router-dom';
import { useState, useEffect } from "react";
import { lazy } from 'react';
import { newSaTranslate, c } from '../qg-components/src/aws-pages/constants/Dictionary';

const AWSHome = ({ userInfo, navigation, basename, handleSignOut }) => {

  const [current, setCurrent] = useState("home");

  const [newNavigation, setNavigation] = useState(navigation());

  const dispatch = useDispatch()

  useEffect(() => {
    setRoute();
    window.addEventListener("hashchange", setRoute);
    return () => window.removeEventListener("hashchange", setRoute);
  }, []);
  const setRoute = () => {
    const location = window.location.href.split("/");
    const pathname = location[location.length - 1];
    setCurrent(pathname ? pathname : "home");
  }
  const languageList = [
    { language: '简体中文', value: 'zh' },
    { language: 'English', value: 'en' }
  ]
  useEffect(() => {
    const langValue = window.localStorage.getItem('aws_language') || 'zh'
    const lang = langValue === 'zh' ? '简体中文' : 'English'
    setLanguage(lang)
  }, [])
  const [language, setLanguage] = useState()

  const handleChangeLanguage = (lang) => {
    setLanguage(lang.language)
    window.localStorage.setItem('aws_language', lang.value)
    // window.location.reload()
    setNavigation(navigation(lang.value));
    dispatch({ type: 'SET_LANGUAGE', value: lang.value });
  }
  const handleNameToAvatarAlt = (name) => {
    if (!name) return
    let str = ''
    name.split(' ').forEach(item => str += item.substring(0, 1).toUpperCase())
    return str
  }
  return (
    <>
      <Home
        navigation={newNavigation}
        basename={basename}
        topBarChildren={
          <div className="flex items-center">
            <Dropdown icon className="mr-4"
              button={language}>
              <ul className="py-1 inline-block whitespace-nowrap ">
                {
                  languageList.map((lang, index) => (
                    <li key={index} className="text-sm hover:bg-gray-100 text-gray-700 px-4 py-2 cursor-pointer" onClick={() => handleChangeLanguage(lang)}>
                      {lang.language}
                    </li>
                  ))
                }
              </ul>
            </Dropdown>
            <Dropdown
              button={<Avatar avatarSrc="https://joeschmoe.io/api/v1/random">{userInfo && handleNameToAvatarAlt(userInfo.username)}</Avatar>}
              direction="right-bottom"
            >
              <div className="px-4 py-3">
                <p className="text-sm">
                  {userInfo && userInfo.username}
                </p>
                <p className="text-sm font-medium text-gray-900 truncate">
                  {userInfo && userInfo.email}
                </p>
              </div>
              <ul className="py-1">
                {/* Logout */}
                <NavLink className="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2"
                  to={current}
                  onClick={handleSignOut}
                >Sign out</NavLink>
              </ul>
            </Dropdown>
          </div>
        }>

      </Home>
    </>
  )
};

export default AWSHome;