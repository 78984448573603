import { useState, forwardRef, useImperativeHandle } from "react"
function Dropdown({ icon, button, direction = 'left-bottom', children, className, id }, ref) {
  const [open, setOpen] = useState(false)
  // 暴露给父组件close方法
  useImperativeHandle(ref, () => ({
    onClose: () => {
      setOpen(false);
    }
  }));
  return (
    <div className={`relative ${className}`} ref={ref} id={id}>
      <button type="button"
        className="font-medium rounded-lg text-sm text-center inline-flex items-center"
        onClick={() => setOpen(!open)}
      >
        {button}
        {
          icon && <svg className={`w-4 h-4 ml-2 ${open ? 'rotate-0' : 'rotate-180'}`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
          </svg>
        }
      </button>

      {/* Dropdown menu */}
      <div className={`bg-white text-base absolute z-50 list-none divide-y divide-gray-100 rounded shadow my-4
        ${direction === 'right-bottom' ? 'right-0' : ''}
        ${direction === 'left-bottom' ? 'left-0' : ''}
        ${open ? 'block' : 'hidden'
        }
      `}>
        {children}
      </div>
    </div>
  )
}
export default forwardRef(Dropdown)