import { useContext, useEffect } from 'react'
import { SidebarContext } from "./context"
import Title from './Title'


function Sub({
  router: { icon, name, path },
  children
}) {
  const { open, showSubs, setShowSubs } = useContext(SidebarContext)

  // 保证可以点开多个下级菜单
  const handleClick = () => {
    const index = showSubs.indexOf(path)
    if (index > -1) {
      showSubs.splice(index, 1)
      setShowSubs([...showSubs])
    } else setShowSubs([...showSubs, path])
  }

  return (
    <>
      <button type="button"
        className="text-base text-gray-900 font-normal rounded-lg flex items-center p-2 group hover:bg-gray-100 transition-all duration-75 w-full"
        onClick={handleClick}
      >
        <Title title={name} Icon={icon} />
        <svg className={`w-6 h-6 ml-4 transform ${showSubs.indexOf(path) > -1 ? 'rotate-180' : 'rotate-0'} `} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
        </svg>
      </button>

      <ul className={`space-y-2 py-2 ${open ? 'ml-8' : 'ml-0'} ${showSubs.indexOf(path) > -1 ? '' : 'hidden'}`}>
        {children}
      </ul>
    </>
  )
}
export default Sub