import { gql } from 'graphql-tag';
/* AppSync */
import { appsyncclient} from '../../api/AppSync';
import { tameUsers } from '../mock/myworklist';
import actions from "../constant";

const initialState = {
  tamelist: []
};

export const managementReducer = (state=initialState, action) => {
  switch (action.type) {
    case actions.GET_MEMBER_MANAGEMENT:
      return { ...state, tamelist: action.payload}
  
    default:
      return state
  }
};

/**
 * 获取management 表格数据
 * @returns 
 */
export const getTeamManageMent = () => (dispatch, getState) => {
  dispatch({ type: actions.GET_MEMBER_MANAGEMENT, payload: tameUsers})
};

export const setEnableOrDisable = () => (dispatch, getState) => {
  alert("request api")
}