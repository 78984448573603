import { ReactNode } from "react"

const Loading: React.FC<{
  id?: string;
  className?: string;
  children?: ReactNode | ReactNode[]
  color?: string;
  size?: number;
  tip?: string;
}> = ({
  className,
  id,
  children,
  color = '#11a1c9',
  size = 50,
  tip
}) => {
    return (
      <div className={`flex items-center relative
        ${className}
      `}>
        <div className={`
            ${children ? 'absolute bg-sprimary-50 opacity-80 w-full h-full flex flex-col items-center justify-center' : ''}
          `}
          id={id}
        >
          <svg className="animate-spin" width={size} height={size} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke={color} strokeWidth="4"></circle>
            <path className="opacity-75" fill={color} d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          {
            tip && <span className="ml-2" style={{ color: color }}>{tip}</span>
          }
        </div>
        <div className="w-full">
          {children}
        </div>

      </div>
    )
  }
export default Loading