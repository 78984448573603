
const initialState = { language: "en" };

export const setLanguageReducer = (state=initialState, action) => {
  switch (action.type) {
    case 'SET_LANGUAGE': {
      return {...state, language: action.value}
    }

    default:
      return state;
  };
}