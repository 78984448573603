import logoUrl from "../../images/logo.png"
function Topbar(props) {
  const { collapseButton, children } = props
  return (
    <nav className="bg-white border-b border-gray-200 fixed z-30 w-full">
      <div className="px-3 py-3 lg:px-5 lg:pl-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-start">
            {/* collapseButton */}
            {collapseButton}
            <a href="/" className="text-md font-semibold flex items-center lg:mr-20">
              <img src={logoUrl} className="h-8 mr-4" alt="Singularity Logo" />
            </a>
          </div>
          <div className="flex items-center">
            <div className="ml-3">
              {children}
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}
export default Topbar