

// Don't edit this file manually!!!
// run bin/run inside qg-components/tools folder
  

export const all = {

  // fileName: Sheet1.json

  ROLE_TECHNOLOGIST: `技术员`, // row=2 sheet=Sheet1
  ROLE_PATHOLOGIST: `病理专家`, // row=3 sheet=Sheet1
  ROLE_TELE_PATHOLOGIST: `远程病理专家`, // row=4 sheet=Sheet1
  ROLE_TEAM_MANAGER: `团队管理者`, // row=5 sheet=Sheet1
  MENU_MY_WORKLIST: `我的工作单`, // row=6 sheet=Sheet1
  MENU_ALL_CASES: `所有病例`, // row=7 sheet=Sheet1
  MENU_CONSULTATION: `咨询`, // row=8 sheet=Sheet1

  // fileName: Sheet2.json

  CASW_ERR_TIPS: `请输入正确的病例号`, // row=2 sheet=Sheet2
  IMG_TYPE_TIPS: `该系统支持以下格式的图像导入：jpg, jpeg, png, ndpi, kfb, tmap
`, // row=3 sheet=Sheet2
  SEARCH_EXPERT_TIPS: `搜索病理专家`, // row=4 sheet=Sheet2
  ASSIGNMENTS_TIPS: `请选择你想要分配任务的病理专家`, // row=5 sheet=Sheet2
  SEND_PATHOLOGY_TIPS: `你确定将病例号234567分配给Neil Sims吗？`, // row=6 sheet=Sheet2
  NOT_SUPPORT_TIPS: `不支持这个文件`, // row=7 sheet=Sheet2
  FILETYPE_NOTSUPPOORT_TIPS: `文件3456789-001-ki67的类型暂时不被支持`, // row=8 sheet=Sheet2
  ENTER_MRN_TIPS: `输入MRN`, // row=9 sheet=Sheet2
  ENTER_HOSPITAL_TIPS: `输入住院号`, // row=10 sheet=Sheet2
  ENTER_SAMPLE_TIPS: `输入试样`, // row=11 sheet=Sheet2
  SELE_SAMPLE_TIPS: `选择试样部分`, // row=13 sheet=Sheet2
  CASE_INFO_FEW_TIPS: `病例信息不完整，您需要添加完整的案件信息才能发送咨询。`, // row=14 sheet=Sheet2
  ENTER_NOTES_DOCTOR_TIPS: `请输入你想要发给远程病理医生的备注`, // row=15 sheet=Sheet2
  SEND_CONSULT_TIPS: `你确定要将病例234567发给Neil Sims和Lily Sam咨询吗？
`, // row=16 sheet=Sheet2
  ENTER_REMARK_TIPS: `添加新的备注`, // row=17 sheet=Sheet2
  ENTER_DESCRIBE_TIPS: `输入描述`, // row=18 sheet=Sheet2
  SEND_PATHOLOGIST_TIPS: `请输入您要发送给病理学家的说明，或通过点击按钮直接发送给病理学家。`, // row=20 sheet=Sheet2
  ENTER_MANUAL_TIPS: `请输入您的说明`, // row=21 sheet=Sheet2
  ATLEASTONE_TIPS: `请输入至少一个项目再点击搜索`, // row=22 sheet=Sheet2
  IMGNOT_MATCH_TIPS: `这张图片不符合这个算法，我们不能保证结果的准确性，你要继续吗？
`, // row=23 sheet=Sheet2
  STARTUP_TECHNICIAN_TIPS: `你确定要将技术员Neil Sims启用吗？`, // row=24 sheet=Sheet2
  DISABLED_PATHOLOGIST_TIPS: `你确定要将远程病理医生Neil Sims禁用吗？`, // row=25 sheet=Sheet2

}
  