import React from "react";

/**
 * 头像组件
 * @param props
 * @param className {string} 为组件最外层添加class
 * @param src {string} 头像图片地址
 * @param size  {string | number} 数字或者【default,large,small】
 * @param shape {string} 【"circle" | "square",】
 * @param alt   img属性
 * @param children  {reactNode} 配合没头像时的文字显示 如果有头像地址则不显示
 * @param bgColor   {string} 图像背景色 配合没头像时使用
 * @param onError   {func} 头像加载失败回调函数
 * @returns reactDOM
 */

interface AvatarProps {
  id?: string;
  className?: string;
  src?: string;
  size?: string | number;
  shape?: "circle" | "square";
  alt?: string;
  children?: React.ReactNode;
  bgColor?: string;
  onError?: () => void
};

const sizeObj = {
  "default": "8",
  "large": "5",
  "small": "12"
};

const Avatar = ({
  src,
  size = "10",
  shape = "circle",
  alt,
  bgColor = "bg-gray-400",
  onError,
  children,
  className,
  id
}: AvatarProps) => {

  let num = Number(size) || 8;
  let classW = `w-${num}`;
  let classH = `h-${num}`;
  let style = `${bgColor} text-white flex text-xs rounded-${shape === "square" ? 'md' : 'full'} focus:ring-4 focus:ring-gray-300`;
  let sizeKeys = Object.keys(sizeObj).includes(size.toString());

  if (sizeKeys) {
    classW = `w-${(sizeObj as any)[size]}`;
    classH = `h-${(sizeObj as any)[size]}`;
  };

  const getImg = (url = "") => {
    // console.log("url ==", url)
    const onerror = () => {
      onError && onError();
    };

    if (url) {
      return (
        <img className={`justify-center items-center
          ${classW}
          ${classH}
          ${style}
          ${className}
        `} src={url} onError={onerror} alt={alt} />
      )
    };
    return (<div className={`justify-center items-center
      ${classW}
      ${classH}
      ${style}
      ${className}
    `}>
      {children}
    </div>)
  };

  return (
    <>
      {getImg(src)}
    </>
  )
};

export default Avatar;
