import { BrowserRouter, HashRouter, Route } from "react-router-dom"
import { useState, Suspense } from 'react'
import Sidebar from '../libs/Sidebar/index';
import Topbar from '../libs/Topbar/index';


function Home({ basename = '', navigation, topBarChildren, children }) {
  const [collapsed, setCollapsed] = useState(false)

  return (
    <HashRouter basename={basename}>
      <div className="App">
        <Topbar collapseButton={
          <>
            <button onClick={() => setCollapsed(!collapsed)}
              className="hidden lg:inline mr-6 text-gray-600 hover:text-gray-900 cursor-pointer p-2 hover:bg-gray-100 rounded"
            >
              <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
              </svg>
            </button>
            <button onClick={() => setCollapsed(!collapsed)}
              className="lg:hidden mr-2 text-gray-600 hover:text-gray-900 cursor-pointer p-2 hover:bg-gray-100 focus:bg-gray-100 focus:ring-2 focus:ring-gray-100 rounded"
            >
              <svg className={`w-6 h-6 ${collapsed ? 'hidden' : 'lg:hidden inline'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
              </svg>
              <svg className={`w-6 h-6 ${!collapsed ? 'hidden' : 'lg:hidden inline'}`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
              </svg>
            </button>
          </>
        }>
          {/* top children */}
          {topBarChildren}
        </Topbar>
        <div className="h-screen flex overflow-hidden bg-white pt-16">
          <Sidebar list={navigation} collapsed={collapsed} />
          <div className="h-full bg-gray-50 relative overflow-hidden flex-1">
            <Suspense fallback={<h1>Loading....</h1>}>
              <RouteView list={navigation} />
              {children}
            </Suspense>
          </div>
        </div>
      </div>
    </HashRouter>
  );
}

function RouteView(props) {
  const { list } = props
  return (
    list.map((router, index) => {
      const { childrens, path, exact, component } = router
      return (
        childrens ? <RouteView key={index} list={childrens} /> : <Route key={index} path={path} component={component} exact={exact} />
      )
    })
  )
}
export default Home;

