

// Don't edit this file manually!!!
// run bin/run inside qg-components/tools folder
  

export const all = {

  // fileName: Sheet1.json

  ROLE_TECHNOLOGIST: "ROLE_TECHNOLOGIST", // row=2 sheet=Sheet1
  ROLE_PATHOLOGIST: "ROLE_PATHOLOGIST", // row=3 sheet=Sheet1
  ROLE_TELE_PATHOLOGIST: "ROLE_TELE_PATHOLOGIST", // row=4 sheet=Sheet1
  ROLE_TEAM_MANAGER: "ROLE_TEAM_MANAGER", // row=5 sheet=Sheet1
  MENU_MY_WORKLIST: "MENU_MY_WORKLIST", // row=6 sheet=Sheet1
  MENU_ALL_CASES: "MENU_ALL_CASES", // row=7 sheet=Sheet1
  MENU_CONSULTATION: "MENU_CONSULTATION", // row=8 sheet=Sheet1

  // fileName: Sheet2.json

  CASW_ERR_TIPS: "CASW_ERR_TIPS", // row=2 sheet=Sheet2
  IMG_TYPE_TIPS: "IMG_TYPE_TIPS", // row=3 sheet=Sheet2
  SEARCH_EXPERT_TIPS: "SEARCH_EXPERT_TIPS", // row=4 sheet=Sheet2
  ASSIGNMENTS_TIPS: "ASSIGNMENTS_TIPS", // row=5 sheet=Sheet2
  SEND_PATHOLOGY_TIPS: "SEND_PATHOLOGY_TIPS", // row=6 sheet=Sheet2
  NOT_SUPPORT_TIPS: "NOT_SUPPORT_TIPS", // row=7 sheet=Sheet2
  FILETYPE_NOTSUPPOORT_TIPS: "FILETYPE_NOTSUPPOORT_TIPS", // row=8 sheet=Sheet2
  ENTER_MRN_TIPS: "ENTER_MRN_TIPS", // row=9 sheet=Sheet2
  ENTER_HOSPITAL_TIPS: "ENTER_HOSPITAL_TIPS", // row=10 sheet=Sheet2
  ENTER_SAMPLE_TIPS: "ENTER_SAMPLE_TIPS", // row=11 sheet=Sheet2
  SELE_SAMPLE_TIPS: "SELE_SAMPLE_TIPS", // row=13 sheet=Sheet2
  CASE_INFO_FEW_TIPS: "CASE_INFO_FEW_TIPS", // row=14 sheet=Sheet2
  ENTER_NOTES_DOCTOR_TIPS: "ENTER_NOTES_DOCTOR_TIPS", // row=15 sheet=Sheet2
  SEND_CONSULT_TIPS: "SEND_CONSULT_TIPS", // row=16 sheet=Sheet2
  ENTER_REMARK_TIPS: "ENTER_REMARK_TIPS", // row=17 sheet=Sheet2
  ENTER_DESCRIBE_TIPS: "ENTER_DESCRIBE_TIPS", // row=18 sheet=Sheet2
  SEND_PATHOLOGIST_TIPS: "SEND_PATHOLOGIST_TIPS", // row=20 sheet=Sheet2
  ENTER_MANUAL_TIPS: "ENTER_MANUAL_TIPS", // row=21 sheet=Sheet2
  ATLEASTONE_TIPS: "ATLEASTONE_TIPS", // row=22 sheet=Sheet2
  IMGNOT_MATCH_TIPS: "IMGNOT_MATCH_TIPS", // row=23 sheet=Sheet2
  STARTUP_TECHNICIAN_TIPS: "STARTUP_TECHNICIAN_TIPS", // row=24 sheet=Sheet2
  DISABLED_PATHOLOGIST_TIPS: "DISABLED_PATHOLOGIST_TIPS", // row=25 sheet=Sheet2

}
