import namor from 'namor'
import Ki67 from './img/ki67.png'
import Avatar from './img/avatar.jpg'

const range = len => {
  const arr = []
  for (let i = 0; i < len; i++) {
    arr.push(i)
  }
  return arr
}
const status = ['Completed', 'Cancelled', 'In progress']
const collect = [true, false]
const newPerson = () => {
  return {
    collect: collect[Math.floor(Math.random() * 2)],
    transaction: 'ljl',
    // transaction: namor.generate({ words: 1, saltLength: 0 }),
    team: 'ljl',
    date: "2021-09-22",
    breastKi67: Math.floor(Math.random() * 30),
    breastPDL1: Math.floor(Math.random() * 100),
    amount: '$' + Math.floor(Math.random() * 10000),
    status: status[Math.floor(Math.random() * 3)],
    caseNumber: Math.floor(Math.random() * 1000000),
    number: `${Math.floor(Math.random() * 10)}/${Math.floor(Math.random() * 100)}`,
    imageName: 'ljl',
    stain: 'ljl',
    lens: `${Math.floor(Math.random() * 10)}X`,
    imgURL: Math.random() > 0.6 ? Ki67 : Avatar,
    caseId: 'ljl',
    patientName: 'ljl',
    userName: 'ljl',
    email: 'email@Singularityai.cn',
    age: Math.floor(Math.random() * 10),
    DOB: "2022-1-27",
    MRN: Math.floor(Math.random() * 100000000),
    gender: 'Male',
    specimen: 'Lung',
    assign: 'ljl',
    name: namor.generate({ words: 1, saltLength: 0 })
  }
}

export default function makeData(...lens) {
  const makeDataLevel = (depth = 0) => {
    const len = lens[depth]
    return range(len).map(d => {
      return {
        ...newPerson(),
        childrens: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined,
      }
    })
  }

  return makeDataLevel()
}