import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { TransitionGroup } from 'react-transition-group'
import Transition from './transition'

import Message, { MessageType } from './message'

export interface MessageApi {
  info: (text: string, duration?: number) => void;
  success: (text: string, duration?: number) => void;
  warning: (text: string, duration?: number) => void;
  error: (text: string, duration?: number) => void;
}

export interface Notice {
  text: string;
  key: string;
  type: MessageType;
  duration?: number;
}

let seed = 0
const now = Date.now()
const getUuid = (): string => {
  const id = seed
  seed += 1
  return `MESSAGE_${now}_${id}`
}

let add: (notice: Notice) => void

export const MessageContainer = () => {
  const [notices, setNotices] = useState<Notice[]>([])
  const maxCount = 10

  const remove = (notice: Notice) => {
    const { key } = notice

    setNotices((prevNotices) => (
      prevNotices.filter(({ key: itemKey }) => key !== itemKey)
    ))
  }

  add = (notice: Notice) => {
    setNotices((prevNotices) => [...prevNotices, notice])
    if (notice.duration === 0) return
    setTimeout(() => {
      remove(notice)
    }, notice.duration || 3000)
  }

  useEffect(() => {
    if (notices.length > maxCount) {
      const [firstNotice] = notices
      remove(firstNotice)
    }
  }, [notices])

  return (
    <div className="fixed top-0 left-1/2 z-50">
      <TransitionGroup>
        {
          notices.map(({ text, key, type }) => (
            <Transition
              timeout={200}
              in
              animation="slide-in-top"
              key={key}
            >
              <Message type={type} text={text} />
            </Transition>
          ))
        }
      </TransitionGroup>
    </div>
  )
}

let el = document.querySelector('#message-wrapper')
if (!el) {
  el = document.createElement('div')
  el.className = 'message-wrapper'
  el.id = 'message-wrapper'
  document.body.append(el)
}

ReactDOM.render(
  <MessageContainer />,
  el
)

const api: MessageApi = {
  info: (text, duration) => {
    add({
      text,
      key: getUuid(),
      type: 'info',
      duration
    })
  },
  success: (text, duration) => {
    add({
      text,
      key: getUuid(),
      type: 'success',
      duration
    })
  },
  warning: (text, duration) => {
    add({
      text,
      key: getUuid(),
      type: 'warning',
      duration
    })
  },
  error: (text, duration) => {
    add({
      text,
      key: getUuid(),
      type: 'danger',
      duration
    })
  }
}

export default api




