

// Don't edit this file manually!!!
// run bin/run inside qg-components/tools folder
  

export const all = {

  // fileName: Sheet1.json

  ROLE_TECHNOLOGIST: `technologist`, // row=2 sheet=Sheet1
  ROLE_PATHOLOGIST: `pathologist`, // row=3 sheet=Sheet1
  ROLE_TELE_PATHOLOGIST: `tele-pathologist`, // row=4 sheet=Sheet1
  ROLE_TEAM_MANAGER: `team-manager`, // row=5 sheet=Sheet1
  MENU_MY_WORKLIST: `my worklist`, // row=6 sheet=Sheet1
  MENU_ALL_CASES: `all cases`, // row=7 sheet=Sheet1
  MENU_CONSULTATION: `consultation`, // row=8 sheet=Sheet1

  // fileName: Sheet2.json

  CASW_ERR_TIPS: `Please enter the correct case number`, // row=2 sheet=Sheet2
  IMG_TYPE_TIPS: `The system supports image import in the following formats: jpg, jpeg, png, ndpi, kfb, tmap`, // row=3 sheet=Sheet2
  SEARCH_EXPERT_TIPS: `Search pathologist`, // row=4 sheet=Sheet2
  ASSIGNMENTS_TIPS: `Please select the pathologist  you want to assign`, // row=5 sheet=Sheet2
  SEND_PATHOLOGY_TIPS: `Are you sure you want to assign Case 234567 to Neil Sims ? `, // row=6 sheet=Sheet2
  NOT_SUPPORT_TIPS: `This file is not supported.`, // row=7 sheet=Sheet2
  FILETYPE_NOTSUPPOORT_TIPS: `Type 3456789-001-ki67 files are not supported at this time!`, // row=8 sheet=Sheet2
  ENTER_MRN_TIPS: `enter MRN`, // row=9 sheet=Sheet2
  ENTER_HOSPITAL_TIPS: `enter DOB`, // row=10 sheet=Sheet2
  ENTER_SAMPLE_TIPS: `enter specimen`, // row=11 sheet=Sheet2
  SELE_SAMPLE_TIPS: `Select Specimen Part`, // row=13 sheet=Sheet2
  CASE_INFO_FEW_TIPS: `The case information is incomplete, you need to add complete case information before you can send your consultation`, // row=14 sheet=Sheet2
  ENTER_NOTES_DOCTOR_TIPS: `Please enter the notes you want to send to the tele-pathologist`, // row=15 sheet=Sheet2
  SEND_CONSULT_TIPS: `Are you sure you want to send Case 234567（10/10） to Neil Sims and Lily Sam for consultation? `, // row=16 sheet=Sheet2
  ENTER_REMARK_TIPS: `add new annotation`, // row=17 sheet=Sheet2
  ENTER_DESCRIBE_TIPS: `enter a description`, // row=18 sheet=Sheet2
  SEND_PATHOLOGIST_TIPS: `Please enter the note you want to send to the pathologist or send it directly to the pathologist by clicking the button`, // row=20 sheet=Sheet2
  ENTER_MANUAL_TIPS: `Please enter your notes`, // row=21 sheet=Sheet2
  ATLEASTONE_TIPS: `Please enter at least one item and then click Search.`, // row=22 sheet=Sheet2
  IMGNOT_MATCH_TIPS: `This image does not match this algorithm, and we can not guarantee the accuracy of the result, do you want to continue?`, // row=23 sheet=Sheet2
  STARTUP_TECHNICIAN_TIPS: `Are you sure to enable Neil Sims  as a technologist?`, // row=24 sheet=Sheet2
  DISABLED_PATHOLOGIST_TIPS: `Are you sure about banning Neil Sims  as a Tele-pathologist?`, // row=25 sheet=Sheet2

}
  