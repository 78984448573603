import {
  ChartPieIcon,
  ClipboardListIcon,
  ViewListIcon,
  UserGroupIcon,
  CogIcon,
  UsersIcon,
  QuestionMarkCircleIcon,
  GlobeIcon
} from '@heroicons/react/solid';

import { lazy } from 'react';
import { newSaTranslate, c, language } from './qg-components/src/aws-pages/constants/Dictionary';
const Dashboard = lazy(() => import('./containers/dashboard/DashboardHomeContainer.js'));
const DashboardProfile = lazy(() => import('./containers/dashboard/DashboardProfileContainer.js'));
const MyWork = lazy(() => import('./containers/myWork/index'));
const AllCase = lazy(() => import('./containers/allcase/AllCaseContainer'));
const Member = lazy(() => import('./containers/team-management/TeamMemberContainer'));
const Invite = lazy(() => import('./containers/team-management/TeamInviteContainer'));
const Profile = lazy(() => import('./containers/settings/SettingsProfileContainer'));
const Permission = lazy(() => import('./containers/settings/SettingsPermissionContainer'));
const Device = lazy(() => import('./containers/settings/SettingsDeviceContainer'));
const incoming = lazy(() => import('./containers/consultation/incoming'));
const outgoing = lazy(() => import('./containers/consultation/outgoing'));
const aiPlayground = lazy(() => import('./containers/aiPlayground/index'));

export const navigation = (language = "en") => {
  return [
    {
      name: 'Dashboard',
      path: '/dashboard',
      component: Dashboard,
      icon: ChartPieIcon,
      exact: true
    },
    {
      name: 'DashboardProfile',
      path: '/dashboardfile',
      component: DashboardProfile,
      icon: ChartPieIcon,
      exact: true
    },
    {
      name: newSaTranslate(language, c.MENU_MY_WORKLIST),
      path: '/my-worklist',
      icon: ClipboardListIcon,
      component: MyWork,
      exact: true
    },
    {
      name: newSaTranslate(language, c.MENU_ALL_CASES),
      path: '/all-cases',
      icon: ViewListIcon,
      component: AllCase,
      exact: true
    },
    {
      name: newSaTranslate(language, c.MENU_CONSULTATION),
      path: '/consultation',
      icon: UserGroupIcon,
      exact: true,
      childrens: [
        { name: 'Incoming', path: '/consultation/incoming', exact: true, component: incoming },
        { name: 'Outgoing', path: '/consultation/outgoing', exact: true, component: outgoing }
      ]
    },
    {
      name: 'AI Playground',
      path: '/ai-playground',
      icon: GlobeIcon,
      exact: true,
      component: aiPlayground
    },
    {
      name: 'Team Management',
      path: '/team',
      icon: UsersIcon,
      exact: true,
      childrens: [
        { name: 'Member', path: '/team/member', exact: true, component: Member },
        { name: 'Invite', path: '/team/invite', exact: true, component: Invite }
      ]
    },
    {
      name: 'Settings',
      path: '/settings',
      icon: CogIcon,
      exact: true,
      childrens: [
        { name: 'Profile', path: '/settings/profile', exact: true, component: Profile },
        { name: 'Permission', path: '/settings/permission', exact: true, component: Permission },
        { name: 'Device', path: '/settings/device', exact: true, component: Device }
      ]
    },
    {
      name: 'Help',
      path: '/help',
      icon: QuestionMarkCircleIcon,
      exact: true
    }
  ]
}
