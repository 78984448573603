import { gql } from 'graphql-tag';
/* AppSync */
import { appsyncclient} from '../../api/AppSync';
import { aiPlayGroundTables } from '../mock/aiPlayground';
import actions from "../constant";

const initialState = {
  tableData: []
};

export const aiPlayGroundReducer = (state=initialState, action) => {
  switch (action.type) {
    case actions.GET_AIPLAYGROUND_TABLES:
      return { ...state, tableData: action.payload}
  
    default:
      return state
  }
};

export const getAiPlayGroundTable = () => (dispatch, getState) => {
  dispatch({ type: actions.GET_AIPLAYGROUND_TABLES, payload: aiPlayGroundTables})
}