import { gql } from 'graphql-tag';
/* AppSync */
import { appsyncclient} from '../../api/AppSync';
import { inComingTables } from "../mock/incoming";
import actions from "../constant";

const initialState = {
  tableData: []
};

export const inComingReducer = (state=initialState, action) => {
  switch ( action.type ) {
    case actions.GET_INCOMING_TABLES:
      return { ...state, tableData: action.payload}
  
    default:
      return state
  }
};

/**
 * 获取incoming表格数据
 * @returns 
 */
export const getInComingTable = () => (dispatch, getState) => {
  dispatch({ type: actions.GET_INCOMING_TABLES, payload: inComingTables})
};

// 搜索表格
export const searchTable = (values) => (dispatch, getState) => {
  alert(`搜索表格 ${JSON.stringify(values)}`)
}