import { gql } from 'graphql-tag';
/* AppSync */
import { appsyncclient} from '../../api/AppSync';
import { outgoingTables } from "../mock/outgoing";
import actions from "../constant";

const initialState = {
  tableData: []
};

export const outgoingReducer = (state=initialState, action) => {
  switch ( action.type ) {
    case actions.GET_OURGOING_TABLES:
      return { ...state, tableData: action.payload}
  
    default:
      return state
  }
};

/**
 * 获取incoming表格数据
 * @returns 
 */
export const getOutgoingTable = () => (dispatch, getState) => {
  dispatch({ type: actions.GET_OURGOING_TABLES, payload: outgoingTables})
};

// 搜索表格
export const searchTable = (values) => (dispatch, getState) => {
  alert(`搜索表格 ${JSON.stringify(values)}`)
}