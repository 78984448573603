import { NavLink } from 'react-router-dom';
import Title from './Title';

function Item ({
  router: { path, exact, name, icon}
}) {
  return (
    <NavLink className="text-base text-gray-900 font-normal rounded-lg flex items-center p-2 hover:bg-gray-100 group"
      activeClassName="text-sprimary-600"
      to={path}
      exact={exact}
    >
      <Title title={name} Icon={icon}/>
    </NavLink>
  )
}
export default Item