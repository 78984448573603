import { SidebarContext } from "./context"
import { useContext } from 'react'
function Title(props) {
  const { Icon, title } = props
  const { open } = useContext(SidebarContext)
  return (
    <>
      { 
        Icon ? <>
          <Icon className="w-6 h-6 flex-shrink-0" aria-hidden="true" />
          <span className={`ml-3 flex-1 text-left whitespace-nowrap transition-all duration-75 ${open ? 'opacity-100' : 'opacity-0'}`}>{title}</span>
        </> : <>
          <span className={`ml-3 flex-1 text-left whitespace-nowrap transition-all duration-75 ${open ? '' : 'hidden'}`}>{title}</span>
          <span className={`w-6 h-6 text-center flex-shrink-0 transition-all duration-75 whitespace-nowrap ${open ? 'hidden' : ''}`}>{title.substring(0,1)}</span>
        </>
      }
    </>
  )
}
export default Title