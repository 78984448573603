export const workListTabs = [{
  caseNumber: "1234354",
  parsingRation: "1/10",
  assignment: "toassign",
  dateReceived: "2021-05-22 17:38:25",
  id: 1
}, {
  caseNumber: "1234354",
  parsingRation: "1/10",
  assignment: "toassign",
  dateReceived: "2021-05-22 17:38:25",
  id: 2
}, {
  caseNumber: "1234354",
  parsingRation: "1/10",
  assignment: "toassign",
  dateReceived: "2021-05-22 17:38:25",
  id: 3
}, {
  caseNumber: "1234354",
  parsingRation: "1/10",
  assignment: "toassign",
  dateReceived: "2021-05-22 17:38:25",
  id: 4
}];

export const workListImgTabs = {
  "1": [{
    imageName: "20220125.ndpi",
    dateReceived: "2021-05-22 17:38:25",
    specimenPart: "Swelling",
    slideType: "FFPE",
    antibody: "Ki-67"
  }, {
    imageName: "20220125.ndpi",
    dateReceived: "2021-05-22 17:38:25",
    specimenPart: "Swelling",
    slideType: "FFPE",
    antibody: "Ki-67"
  }, {
    imageName: "20220125.ndpi",
    dateReceived: "2021-05-22 17:38:25",
    specimenPart: "Swelling",
    slideType: "FFPE",
    antibody: "Ki-67"
  }],
  "2": [{
    imageName: "20220125.ndpi",
    dateReceived: "2021-05-22 17:38:25",
    specimenPart: "Swelling",
    slideType: "FFPE",
    antibody: "Ki-67"
  }, {
    imageName: "20220125.ndpi",
    dateReceived: "2021-05-22 17:38:25",
    specimenPart: "Swelling",
    slideType: "FFPE",
    antibody: "Ki-67"
  }, {
    imageName: "20220125.ndpi",
    dateReceived: "2021-05-22 17:38:25",
    specimenPart: "Swelling",
    slideType: "FFPE",
    antibody: "Ki-67"
  }],
  "3": [{
    imageName: "20220125.ndpi",
    dateReceived: "2021-05-22 17:38:25",
    specimenPart: "Swelling",
    slideType: "FFPE",
    antibody: "Ki-67"
  }, {
    imageName: "20220125.ndpi",
    dateReceived: "2021-05-22 17:38:25",
    specimenPart: "Swelling",
    slideType: "FFPE",
    antibody: "Ki-67"
  }],
  "4": [{
    imageName: "20220125.ndpi",
    dateReceived: "2021-05-22 17:38:25",
    specimenPart: "Swelling",
    slideType: "FFPE",
    antibody: "Ki-67"
  }]
};

export const tameUsers = [{
  name: "王小虎",
  avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
  email: "bonnie@exampie.com",
  role: "Pathologist",
  country: "United States",
  status: "Active",
  id: "1",
}, {
  name: "王小虎",
  avatar: "https://joeschmoe.io/api/v1/random",
  email: "bonnie@exampie.com",
  role: "Tele-Pathologist",
  country: "United States",
  status: "Offline",
  id: "2"
},{
  name: "王小虎",
  avatar: "https://joeschmoe.io/api/v1/random",
  email: "bonnie@exampie.com",
  role: "Technologist",
  country: "United States",
  status: "Offline",
  id: "3",
}, {
  name: "王小虎",
  avatar: "",
  email: "bonnie@exampie.com",
  role: "Pathologist",
  country: "United States",
  status: "Offline",
  id: "4"
},{
  name: "王小虎",
  avatar: "https://joeschmoe.io/api/v1/random",
  email: "bonnie@exampie.com",
  role: "Pathologist",
  country: "United States",
  status: "Offline",
  id: "5",
}, {
  name: "王小虎",
  avatar: "https://joeschmoe.io/api/v1/random",
  email: "bonnie@exampie.com",
  role: "Pathologist",
  country: "United States",
  status: "Active",
  id: "6"
},{
  name: "王小虎",
  avatar: "https://joeschmoe.io/api/v1/random",
  email: "bonnie@exampie.com",
  role: "Pathologist",
  country: "United States",
  status: "Active",
  id: "7",
}, {
  name: "王小虎",
  avatar: "https://joeschmoe.io/api/v1/random",
  email: "bonnie@exampie.com",
  role: "Pathologist",
  country: "United States",
  status: "Active",
  id: "8"
}]