const consts = {
  SET_IMAGE_SRC: 'SET_IMAGE_SRC',
  SET_MULTIPLE: 'SET_MULTIPLE',
  SET_IS_AWB: 'SET_IS_AWB',
  SET_ANALYSE_STATUS: 'SET_ANALYSE_STATUS',
  SET_ANALYSE_RESULT:'SET_ANALYSE_RESULT',
  SET_Ki67_MULTI_PARAMS: 'SET_Ki67_MULTI_PARAMS',
  SET_LANGUAGE: "SET_LANGUAGE",
  GET_WORK_TABLES: "GET_WORK_TABLES",
  GET_WORK_IMG_TABLES: "GET_WORK_IMG_TABLES",
  GET_ASSIGN_LIST: "GET_ASSIGN_LIST",
  GET_INCOMING_TABLES: "GET_INCOMING_TABLES",
  GET_OURGOING_TABLES: "GET_OURGOING_TABLES",
  GET_MEMBER_MANAGEMENT: "GET_MEMBER_MANAGEMENT",
  GET_AIPLAYGROUND_TABLES: "GET_AIPLAYGROUND_TABLES"
}
export default consts