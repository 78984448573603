export const aiPlayGroundTables = [{
  imageName: "3456789-001-ki67-40X-011 2018-12-20 09.20.02.jpg",
  dateReceived: "2021-05-22 17:38:25",
  specimenPart: "Swelling",
  slideType: "FFPE",
  antibody: "Ki-67",
  aiAnalysisStatus: "analyzing successfully",
  caseNumber: "2021-09176543",
  mrn: "2022-0123452",
  dob: "17/02/2022",
  patientName: "Mike Kim",
  gender: "Male",
  age: "18"
},{
  imageName: "3456789-001-ki67-40X-011 2018-12-20 09.20.02.jpg",
  dateReceived: "2021-05-22 17:38:25",
  specimenPart: "Swelling",
  slideType: "HER2",
  antibody: "Ki-67",
  aiAnalysisStatus: "analyzing unsuccessfully",
  caseNumber: "2021-09176543",
  mrn: "2022-0123452",
  dob: "17/02/2022",
  patientName: "Mike Kim",
  gender: "Female",
  age: "34"
},{
  imageName: "3456789-001-ki67-40X-011 2018-12-20 09.20.02.jpg",
  dateReceived: "2021-05-22 17:38:25",
  specimenPart: "Swelling",
  slideType: "FFPE",
  antibody: "Ki-67",
  aiAnalysisStatus: "analyzing unsuccessfully",
  caseNumber: "2021-09176543",
  mrn: "2022-0123452",
  dob: "17/02/2022",
  patientName: "Mike Kim",
  gender: "Female",
  age: "36"
},{
  imageName: "3456789-001-ki67-40X-011 2018-12-20 09.20.02.jpg",
  dateReceived: "2021-05-22 17:38:25",
  specimenPart: "Swelling",
  slideType: "HER2",
  antibody: "Ki-67",
  aiAnalysisStatus: "to analyse",
  caseNumber: "2021-09176543",
  mrn: "2022-0123452",
  dob: "17/02/2022",
  patientName: "Mike Kim",
  gender: "Female",
  age: "45"
},{
  imageName: "3456789-001-ki67-40X-011 2018-12-20 09.20.02.jpg",
  dateReceived: "2021-05-22 17:38:25",
  specimenPart: "Swelling",
  slideType: "FFPE",
  antibody: "Ki-67",
  aiAnalysisStatus: "analyzing successfully",
  caseNumber: "2021-09176543",
  mrn: "2022-0123452",
  dob: "17/02/2022",
  patientName: "Mike Kim",
  gender: "Male",
  age: "15"
},{
  imageName: "3456789-001-ki67-40X-011 2018-12-20 09.20.02.jpg",
  dateReceived: "2021-05-22 17:38:25",
  specimenPart: "Swelling",
  slideType: "FFPE",
  antibody: "Ki-67",
  aiAnalysisStatus: "analyzing successfully",
  caseNumber: "2021-09176543",
  mrn: "2022-0123452",
  dob: "17/02/2022",
  patientName: "Mike Kim",
  gender: "Male",
  age: "25"
},{
  imageName: "3456789-001-ki67-40X-011 2018-12-20 09.20.02.jpg",
  dateReceived: "2021-05-22 17:38:25",
  specimenPart: "Swelling",
  slideType: "FFPE",
  antibody: "Ki-67",
  aiAnalysisStatus: "analyzing successfully",
  caseNumber: "2021-09176543",
  mrn: "2022-0123452",
  dob: "17/02/2022",
  patientName: "Mike Kim",
  gender: "Male",
  age: "52"
},{
  imageName: "3456789-001-ki67-40X-011 2018-12-20 09.20.02.jpg",
  dateReceived: "2021-05-22 17:38:25",
  specimenPart: "Swelling",
  slideType: "FFPE",
  antibody: "Ki-67",
  aiAnalysisStatus: "analyzing successfully",
  caseNumber: "2021-09176543",
  mrn: "2022-0123452",
  dob: "17/02/2022",
  patientName: "Mike Kim",
  gender: "Male",
  age: "37"
},{
  imageName: "3456789-001-ki67-40X-011 2018-12-20 09.20.02.jpg",
  dateReceived: "2021-05-22 17:38:25",
  specimenPart: "Swelling",
  slideType: "FFPE",
  antibody: "Ki-67",
  aiAnalysisStatus: "analyzing successfully",
  caseNumber: "2021-09176543",
  mrn: "2022-0123452",
  dob: "17/02/2022",
  patientName: "Mike Kim",
  gender: "Male",
  age: "47"
},{
  imageName: "3456789-001-ki67-40X-011 2018-12-20 09.20.02.jpg",
  dateReceived: "2021-05-22 17:38:25",
  specimenPart: "Swelling",
  slideType: "FFPE",
  antibody: "Ki-67",
  aiAnalysisStatus: "analyzing successfully",
  caseNumber: "2021-09176543",
  mrn: "2022-0123452",
  dob: "17/02/2022",
  patientName: "Mike Kim",
  gender: "Male",
  age: "31"
},{
  imageName: "3456789-001-ki67-40X-011 2018-12-20 09.20.02.jpg",
  dateReceived: "2021-05-22 17:38:25",
  specimenPart: "Swelling",
  slideType: "FFPE",
  antibody: "Ki-67",
  aiAnalysisStatus: "analyzing successfully",
  caseNumber: "2021-09176543",
  mrn: "2022-0123452",
  dob: "17/02/2022",
  patientName: "Mike Kim",
  gender: "Male",
  age: "24"
},{
  imageName: "3456789-001-ki67-40X-011 2018-12-20 09.20.02.jpg",
  dateReceived: "2021-05-22 17:38:25",
  specimenPart: "Swelling",
  slideType: "FFPE",
  antibody: "Ki-67",
  aiAnalysisStatus: "analyzing successfully",
  caseNumber: "2021-09176543",
  mrn: "2022-0123452",
  dob: "17/02/2022",
  patientName: "Mike Kim",
  gender: "Male",
  age: "26"
},{
  imageName: "3456789-001-ki67-40X-011 2018-12-20 09.20.02.jpg",
  dateReceived: "2021-05-22 17:38:25",
  specimenPart: "Swelling",
  slideType: "FFPE",
  antibody: "Ki-67",
  aiAnalysisStatus: "analyzing successfully",
  caseNumber: "2021-09176543",
  mrn: "2022-0123452",
  dob: "17/02/2022",
  patientName: "Mike Kim",
  gender: "Male",
  age: "33"
}]