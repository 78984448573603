import { FC, ReactElement } from 'react'
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
  InformationCircleIcon
} from '@heroicons/react/outline'
export type MessageType = 'info' | 'success' | 'danger' | 'warning'

export interface MessageProps {
  text: string;
  type: MessageType;
}
const classes = {
  info: "text-blue-600",
  success: "text-sprimary-600",
  danger: "text-red-600",
  warning: "text-yellow-600"
}
const Message: FC<MessageProps> = (props: MessageProps) => {
  const { text, type } = props

  const renderIcon = (messageType: MessageType): ReactElement => {
    let MessageIcon

    switch (messageType) {
      case 'success':
        MessageIcon = CheckCircleIcon
        break
      case 'danger':
        MessageIcon = XCircleIcon
        break
      case 'warning':
        MessageIcon = ExclamationCircleIcon
        break
      case 'info':
      default:
        MessageIcon = InformationCircleIcon
        break
    }

    return <MessageIcon className="w-5 h-5" />
  }

  return (
    <div className={`shadow-lg p-2 w-64 text-gray-600 rounded-md bg-white m-6
    `}>
      <div className="inline-flex items-center">
        <div className={`mr-2 ${classes[type]}`}>
          {renderIcon(type)}
        </div>
        <div className="text">
          {text}
        </div>
      </div>
    </div>
  )
}

export default Message